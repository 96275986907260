import React from 'react';
import Carousel from '../components/js/Carousel';
import './GolfPracticeRangeIntroduction.scss';

const GolfPracticeRangeIntroduction: React.FC = () => {
  return (
    <div className="introduction">
      <div className="intro-desc">
        <p className="intro-title">最高のレッスン環境</p>
        <p className="intro-caption">
          <span>
            フレンズアカデミーの練習メニューでスキルアップ
            <br />
            あなたのゴルフライフを次のレベルへ
            <br />
            そして、世界のプレイヤーと競技しよう！
          </span>
        </p>
      </div>
      <Carousel />
    </div>
  );
};

export default GolfPracticeRangeIntroduction;
