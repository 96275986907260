export enum InquiryReasonContacting {
  REASON_CONTACTING_1 = 'REASON_CONTACTING_1',

  REASON_CONTACTING_2 = 'REASON_CONTACTING_2',

  REASON_CONTACTING_3 = 'REASON_CONTACTING_3',

  REASON_CONTACTING_4 = 'REASON_CONTACTING_4',
}
export const InquiryReasonContactingLabels = {
  [InquiryReasonContacting.REASON_CONTACTING_1]: 'ホームページ',
  [InquiryReasonContacting.REASON_CONTACTING_2]: '知人からの紹介',
  [InquiryReasonContacting.REASON_CONTACTING_3]: '店舗/施設の訪問後',
  [InquiryReasonContacting.REASON_CONTACTING_4]: 'その他',
};
