import './press.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

export const Press = () => {
  const location = useLocation();

  type press = { url: string; img: string; title: string; type: string; date: string };

  const pressListAll: press[] = [
    {
      url: 'https://prtimes.jp/main/html/rd/p/000000002.000062600.html',
      img: 'content/images/press_room_img1.png',
      title: '【Fortify】Webサイト脆弱性診断サービスが完全無料！ Webサイトの攻撃遮断サービス',
      type: 'コーポレート',
      date: '2020.09.08',
    },
    {
      url: 'https://prtimes.jp/main/html/rd/p/000000001.000062600.html?fbclid=IwAR0Q22wjKJCUrHgJBMrp079F56-gR2xCGnIZZS6w1KSrDIjtIe4VTT4AoEM',
      img: 'content/images/press_room_img2.png',
      title: '株式会社スホ、韓国ネイバービジネスプラットフォームとパートナー契約を締結',
      type: 'コーポレート',
      date: '2020.07.30',
    },
    {
      url: 'https://www.dreamnews.jp/press/0000216456/',
      img: 'content/images/press_room_img3.png',
      title: 'Cloud WAFサービス「Fortify」の無償提供キャンペーン＆「Fortify」ならSSL...',
      type: 'コーポレート',
      date: '2020.06.04',
    },
    {
      url: 'https://www.dreamnews.jp/press/0000204904/',
      img: 'content/images/press_room_img4.png',
      title: 'Whatap ITモニタリングサービスを開始',
      type: 'コーポレート',
      date: '2019.11.1',
    },
    {
      url: 'https://www.dreamnews.jp/press/0000160814/',
      img: 'content/images/press_room_img5.png',
      title: 'WAF(Web Application Firewall)サービス - Fortifyのキャンペーン',
      type: 'コーポレート',
      date: '2017.10.04',
    },
    {
      url: 'https://www.dreamnews.jp/press/0000160190/',
      img: 'content/images/press_room_img6.png',
      title: 'WAF(Web Application Firewall)サービスの開始',
      type: 'コーポレート',
      date: '2017.09.20',
    },
    {
      url: 'https://www.dreamnews.jp/press/0000160652/',
      img: 'content/images/press_room_img7.png',
      title: '東京ゲームショウ2017「GYRO VR」展示のご報告',
      type: 'コーポレート',
      date: '2017.09.25',
    },
    {
      url: 'https://www.dreamnews.jp/press/0000141661/',
      img: 'content/images/press_room_img8.png',
      title: '株式会社スホがSDNプラットフォーム供給企業Aryakaとパートナー契約を締結',
      type: 'コーポレート',
      date: '2016.10.27',
    },
  ];

  return (
    <div className="StudioCanvas">
      <div className="sd ">
        <div className="sd mainTitle pressTitle">
          <p className="text sd">PRESS ROOM</p>
          <div className="sd"></div>
        </div>
        <Row className="row_box">
          {pressListAll.map((press, i) => (
            <Col className="col" md="3" key={`entity-${i}`}>
              <a href={press.url} rel="noopener noreferrer" target="_blank" className="sd">
                <img src={press.img} className="sd" />
                <p className="text sd">
                  {press.title}
                  <br />
                </p>
                <div className="sd">
                  <div className="sd">
                    <p className="text sd">{press.type}</p>
                  </div>
                  <div className="sd">
                    <p className="text sd">{press.date}</p>
                  </div>
                </div>
              </a>
            </Col>
          ))}
        </Row>

        {/* <div className="sd pressRow">
        {pressList.map((press, i) => (
          
          <a href={press.url} rel="noopener" target="_blank" className="sd">
            <img src={press.img} className="sd"/>
            <p className="text sd">{press.title}<br/></p>
            <div className="sd">
              <div className="sd">
                <p className="text sd">{press.type}</p>
              </div>
              <div className="sd">
                <p className="text sd">{press.date}</p>
              </div>
            </div>
          </a>
        ))}
        </div>

        <div className="sd pressRow">
        {pressList2.map((press, i) => (
          
          <a href={press.url} rel="noopener" target="_blank" className="sd">
            <img src={press.img} className="sd"/>
            <p className="text sd">{press.title}<br/></p>
            <div className="sd">
              <div className="sd">
                <p className="text sd">{press.type}</p>
              </div>
              <div className="sd">
                <p className="text sd">{press.date}</p>
              </div>
            </div>
          </a>
        ))}
        </div>*/}
      </div>
    </div>
  );
};

export default Press;
