import React from 'react';
import './GolfHeader.scss';

const GolfHeader = ({ menu }) => {
  const headerClass = `header${menu !== 'main' ? ' sub-header' : ''}`;

  const scrollTop = () => {
    document.getElementById('golfContent').scrollIntoView();
  };

  return (
    <>
      {/* ここに<meta>タグと<script>タグのコードを配置 */}

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      <meta name="keywords" content="MirakuVX, フレンズアカデミー, ゴルフ練習場, カカオゴルフ, スクリーン練習場" />
      <meta name="description" content="誰でも簡単にゴルフが楽しめ、誰でも簡単に起業できるフレンズアカデミー" />

      <div id="wrapper">
        <div className={headerClass}>
          <h1 className="bi">
            <a aria-current="page" className="home-link" onClick={scrollTop}>
              FRIENDS ACADEMY
            </a>
          </h1>
          <ul className="head-util">
            <li className="head-util-item">
              <a href="/contact" target="_blank" rel="noreferrer" className="head-util-link">
                お問い合わせ
              </a>
            </li>
          </ul>

          <a href="#" className="total-menu-btn">
            <span className="total-menu-ico total-menu-ico-1"></span>
            <span className="total-menu-ico total-menu-ico-2"></span>
            <span className="total-menu-ico total-menu-ico-3"></span>
          </a>
          <div className="total-menu">
            <ul className="total-menu-list">
              <li className="total-menu-item">
                <a href="/contact" className="total-menu-link" target="_blank">
                  お問い合わせ
                </a>
              </li>
            </ul>
          </div>

          {/* 他のコード */}
        </div>
      </div>
    </>
  );
};

export default GolfHeader;
