// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("app/components/img/slider-prev.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("app/components/img/slider-next.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slick-prev:hover,\n.slick-prev:focus {\n  background: none;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: 50% 50%;\n  background-size: 12px 18px;\n}\n\n.slick-next:hover,\n.slick-next:focus {\n  background: none;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-position: 50% 50%;\n  background-size: 12px 18px;\n}\n\n.slick-list {\n  border-radius: 30px 30px 30px 30px;\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/golf/components/js/Carousel.scss"],"names":[],"mappings":"AAAA;;EAEE,gBAAA;EACA,yDAAA;EACA,4BAAA;EACA,4BAAA;EACA,0BAAA;AACF;;AAEA;;EAEE,gBAAA;EACA,yDAAA;EACA,4BAAA;EACA,4BAAA;EACA,0BAAA;AACF;;AAEA;EACE,kCAAA;AACF","sourcesContent":[".slick-prev:hover,\r\n.slick-prev:focus {\r\n  background: none;\r\n  background-image: url('app/components/img/slider-prev.png');\r\n  background-repeat: no-repeat;\r\n  background-position: 50% 50%;\r\n  background-size: 12px 18px;\r\n}\r\n\r\n.slick-next:hover,\r\n.slick-next:focus {\r\n  background: none;\r\n  background-image: url('app/components/img/slider-next.png');\r\n  background-repeat: no-repeat;\r\n  background-position: 50% 50%;\r\n  background-size: 12px 18px;\r\n}\r\n\r\n.slick-list {\r\n  border-radius: 30px 30px 30px 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
