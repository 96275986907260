export enum InquiryProductConsidering {
  MGA = 'MGA',

  MGH = 'MGH',

}
export const InquiryProductConsideringLabels = {
  [InquiryProductConsidering.MGA]: 'MirakuVX GOLF ACADEMY（練習専用）',
  [InquiryProductConsidering.MGH]: 'MirakuVX GOLF HYBRID（ラウンド・練習・ゲーム）',
};
