import React from 'react';
import GolfPracticeRangeIntroduction from './GolfPracticeRangeIntroduction';
import GolfPracticeRangeDescription from './GolfPracticeRangeDescription';
import GolfPracticeRangeFriendsAcademy from './GolfPracticeRangeFriendsAcademy';

const GolfPracticeRangePage: React.FC = () => {
  return (
    <div id="golfContent">
      <GolfPracticeRangeIntroduction />
      <GolfPracticeRangeDescription />
      <GolfPracticeRangeFriendsAcademy />
    </div>
  );
};

export default GolfPracticeRangePage;
