import React from 'react';
import { UncontrolledTooltip, Badge } from 'reactstrap';

interface CustomLabelWithTooltipProps {
  label: string;
  htmlFor: string;
  id: string;
  borderBottom?: boolean;
}

/**
 * ツールチップ付きのフォームラベル
 * @param label ラベル 末尾に※があると、そこだけ赤色で表示され、ツールチップには、（必須）と表示される
 * @param htmlFor 連携するフィールドのID
 * @param id このラベルのID
 * @returns
 */
export const CustomLabelWithTooltip: React.FC<CustomLabelWithTooltipProps> = ({ label, htmlFor, id }) => {
  const hasAsterisk = label.endsWith('※');
  const labelText = hasAsterisk ? label.slice(0, -1) : label;
  const tooltipText = hasAsterisk ? labelText + '（必須）' : labelText;
  const divClass = hasAsterisk ? 'col-md-2 inquiry-label' : 'col-md-3 inquiry-label';

  return (
    <>
      <div className={divClass}>
        <label htmlFor={htmlFor} className="form-label" id={id}>
          {labelText}
        </label>
        <UncontrolledTooltip target={id}>{tooltipText}</UncontrolledTooltip>
      </div>
      {hasAsterisk && (
        <div className="col-md-1">
          <Badge className="font-size-14 inquiry-btn-color">必須</Badge>
        </div>
      )}
    </>
  );
};
