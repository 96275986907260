import './header.scss';
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavbarToggler, Collapse, Row, Col } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  const location = useLocation();

  useEffect(() => {
    const hashId = location.hash.replace('#', '');
    if (hashId !== '' && document.getElementById(hashId)) {
      document.getElementById(hashId).scrollIntoView();
    }
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" light expand="md" fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Link aria-current="page" className="brand-logo navbar-brand active" to="/#root">
          <div className="brand-title">
            <img className="logo-img" src="content/images/logo.png" />
            <div className="comp-irxld1lr">
              <p className="pStyle1">MIRAKU</p>
              <p className="pStyle2">New Experience </p>
            </div>
          </div>
        </Link>
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar>
            <Link to="/#aboutScroll" aria-current="page" className="d-flex align-items-center nav-link active nav-item" onClick={closeMenu}>
              About
            </Link>
            <Link
              to="/#servicesScroll"
              aria-current="page"
              className="d-flex align-items-center nav-link active nav-item"
              onClick={closeMenu}
            >
              Services
            </Link>
            {/* TODO : 記事内容が追加する時点で復活させる */}
            {/* <Link to="/press" aria-current="page" className="d-flex align-items-center nav-link active nav-item" onClick={closeMenu}>
              Press
            </Link> */}
            <Link to="/#footer" aria-current="page" className="d-flex align-items-center nav-link active nav-item" onClick={closeMenu}>
              Contact
            </Link>
            <Link
              to="/contact"
              aria-current="page"
              className="d-flex align-items-center nav-link active nav-item"
              onClick={closeMenu}
              target="_blank"
            >
              お問い合わせ
            </Link>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
