import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import './Carousel.scss';

const CustomArrowNext = (props) => {
    const { className, onClick } = props;
    return (
      <a
        href="#"
        className={className + ' slider-btn slider-next'}
        onClick={onClick}
      >
        next
      </a>
    );
};
 
const CustomArrowPrev = (props) => {
    const { className, onClick } = props;
    return (
        <a
        href="#"
        className={className + ' slider-btn slider-prev'}
        onClick={onClick}
        >
        prev
        </a>
    );
};

const Carousel = () => {
    // アクティブスライドの状態を保持するための useState フックを使用
    const [activeSlide, setActiveSlide] = useState(0);

    // カルーセルの設定オブジェクト
    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true, // ドットインジケータを表示
        infinite: true, // 無限ループ（最後のスライドの後に最初のスライドに戻る）
        speed: 500, // アニメーションの速度（ミリ秒）
        slidesToShow: 1, // 一度に表示するスライド数
        slidesToScroll: 1, // スクロールするスライド数
        nextArrow: <CustomArrowNext />, // 次の矢印カスタムコンポーネント
        prevArrow: <CustomArrowPrev />, // 前の矢印カスタムコンポーネント
        appendDots: dots => <ul className="slick-dots">{dots}</ul>, // ドットインジケータのスタイルをカスタマイズ
        beforeChange: (current, next) => setActiveSlide(next), // スライドが変更される前に実行される関数（アクティブスライドの状態を更新）
    };

    const carouselTexts = [
        {
            title: 'グラフィック',
            content: '高品質<br />グラフィック環境は<br />リアルな練習場で<br />練習している気分を<br />感じさせます。',
        },
        {
            title: '精度',
            content:
                '実際と異なる<br />意味のない練習は終わり、<br />フィールドと同様に<br />実現できます。',
        },
        {
            title: '分析力',
            content:
                '有意義な<br />データだけを選別して、<br />必要なデータ情報を<br />分かりやすく<br />提供します。',
        },
        {
            title: '便利さ',
            content:
                'ユーザーに<br />最適化された構成で<br />誰でも簡単かつ快適に<br />練習できます。',
        }
    ];

    // スライダーの参照を保持するための useRef フックを使用
    const sliderRef = useRef(null);

    // テキストがクリックされたときに呼び出される関数
    const handleTextClick = (index) => {
      setActiveSlide(index); // アクティブスライドをクリックされたスライドのインデックスに設定
      // sliderRef（スライダーコンポーネントへの参照）を使って、slickGoToメソッドを呼び出し、クリックされたスライドのインデックスに移動
      sliderRef.current.slickGoTo(index);
    };

  return (
    <>
        <div className="carousel-top">
            <div className="client-slider slick-initialized slick-slider slick-dotted">
                <Slider {...settings} ref={sliderRef}>
                <div className="client-slide">
                    <img src="content/golf/img/golf_practice_intro_1.jpg" alt="クライアント画像" />
                </div>
                <div className="client-slide">
                    <img src="content/golf/img/golf_practice_intro_2.jpg" alt="クライアント画像" />
                </div>
                <div className="client-slide">
                    <img src="content/golf/img/golf_practice_intro_3.jpg" alt="クライアント画像" />
                </div>
                <div className="client-slide">
                    <img src="content/golf/img/golf_practice_intro_4.jpg" alt="クライアント画像" />
                </div>
                </Slider>
            </div>
 
        </div>
        <ul className="client-info-list">
        {
          // carouselTexts配列をmap関数を使って反復処理し、各テキスト要素に対してリストアイテムを作成
          carouselTexts.map((text, index) => (
            <li
            key={index} // リストアイテムに一意のkey属性を割り当てるためにindexを使用
            // classNameに基本のスタイルと、現在のスライドがアクティブなら'active'クラスを追加
            className={`client-info-item client-info-item-${index + 1} ${activeSlide === index ? 'active' : ''}`}
            onClick={() => handleTextClick(index)} // テキストがクリックされたときにhandleTextClick関数を呼び出し、indexを渡す
            >
            <div className="client-txt-area">
                <p className="client-ttl">{text.title}</p> {/* タイトルを表示 */}
                {/* dangerouslySetInnerHTMLを使用してHTMLコンテンツを表示（改行タグが含まれるため） */}
                <p className="client-txt" dangerouslySetInnerHTML={{ __html: text.content }}></p>
            </div>
            </li>
          ))
        }
        </ul>
    </>
    
  );
};

export default Carousel;
