// Import jQuery
import 'jquery/dist/jquery.min.js';

// Import Slick Carousel
import 'slick-carousel/slick/slick.min.js';

// Import ScrollMagic
import 'scrollmagic/scrollmagic/minified/ScrollMagic.min.js';

// Import jQuery Selectric
import 'jquery-selectric';
import 'jquery-selectric/src/selectric.scss';

// Import Slick Carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import your custom JS file
// import '../components/js/common.js';
import '../components/js/common';
