import dayjs from 'dayjs';
import { IInquiry } from 'app/shared/model/inquiry.model';

export interface IInquiryNote {
  id?: number;
  content?: string;
  createdDate?: string;
  inquiry?: IInquiry | null;
}

export const defaultValue: Readonly<IInquiryNote> = {};
