export enum InquiryBusinessType {
  BUSINESS_TYPE_1 = 'BUSINESS_TYPE_1',

  BUSINESS_TYPE_2 = 'BUSINESS_TYPE_2',

  BUSINESS_TYPE_3 = 'BUSINESS_TYPE_3',

  BUSINESS_TYPE_4 = 'BUSINESS_TYPE_4',

  BUSINESS_TYPE_5 = 'BUSINESS_TYPE_5',

  BUSINESS_TYPE_6 = 'BUSINESS_TYPE_6',

  BUSINESS_TYPE_7 = 'BUSINESS_TYPE_7',

  BUSINESS_TYPE_8 = 'BUSINESS_TYPE_8',
}

export const InquiryBusinessTypeLabels = {
  [InquiryBusinessType.BUSINESS_TYPE_1]: 'インドアゴルフ練習場',
  [InquiryBusinessType.BUSINESS_TYPE_2]: 'フィットネスクラブ',
  [InquiryBusinessType.BUSINESS_TYPE_3]: 'ゴルフバー',
  [InquiryBusinessType.BUSINESS_TYPE_4]: 'アミューズメント',
  [InquiryBusinessType.BUSINESS_TYPE_5]: 'マンション',
  [InquiryBusinessType.BUSINESS_TYPE_6]: '福利厚生',
  [InquiryBusinessType.BUSINESS_TYPE_7]: '個人宅',
  [InquiryBusinessType.BUSINESS_TYPE_8]: 'その他',
};
