import './GolfPracticeRangeDescription.scss';

import React, { useState } from 'react';
import { Modal } from 'reactstrap';

const GolfPracticeRangeDescription: React.FC = () => {
  const [sensorModal, setSensorModal] = useState(false);
  const [cameraModal, setCameraModal] = useState(false);
  const [kioskModal, setKioskModal] = useState(false);
  const [keypadModal, setKeypadModal] = useState(false);

  const sensorToggle = () => setSensorModal(!sensorModal);
  const cameraToggle = () => setCameraModal(!cameraModal);
  const kioskToggle = () => setKioskModal(!kioskModal);
  const keypadToggle = () => setKeypadModal(!keypadModal);

  return (
    <div className="sub-section section-contents">
      <div className="contents-inner">
        <div className="sub-ttl-area">
          <p className="hardware-title-main-txt">
            より高度なハードウェアで
            <br />
            現実感のある練習場を実現
          </p>
        </div>
        <div className="hardware">
          <div className="hardware-inner">
            <img src="content/golf/img/hardware.png" alt="ハードウェアイメージ" className="mobile-hide" />
            <img src="content/golf/img/m-hardware.png" alt="ハードウェアイメージ" className="mobile-show" />
            <button className="hardware-btn hardware-btn-1" onClick={sensorToggle}></button>
            <button className="hardware-btn hardware-btn-2" onClick={cameraToggle}></button>
            <button className="hardware-btn hardware-btn-3" onClick={kioskToggle}></button>
            <button className="hardware-btn hardware-btn-4" onClick={keypadToggle}></button>
          </div>
          <div className="hardware-info">
            <p className="hardware-info-txt">ボタンを押すとその詳細を確認できます。</p>
          </div>
        </div>
      </div>
      <Modal style={{ paddingTop: '70px' }} isOpen={sensorModal} toggle={sensorToggle}>
        <div className="hardware-pop-inner">
          <button className="pop-close" onClick={sensorToggle}></button>
          <div className="hardware-pop-img">
            <img src="content/golf/img/sensor.png" alt="" />
          </div>
          <div className="pop-txt-area">
            <p className="pop-ttl">ビューセンサー</p>
            <p className="pop-txt">
              3,800FPS超高速カメラセンサーで
              <br />
              ボールの速度、方向、スピンを測定し、
              <br />
              正確な距離と球状描写
            </p>
          </div>
        </div>
      </Modal>

      <Modal style={{ paddingTop: '70px' }} isOpen={cameraModal} toggle={cameraToggle}>
        <div className="hardware-pop-inner">
          <button className="pop-close" onClick={cameraToggle}></button>
          <div className="hardware-pop-img">
            <img src="content/golf/img/camera.png" alt="" />
          </div>
          <div className="pop-txt-area">
            <p className="pop-ttl">カメラ</p>
            <p className="pop-txt">
              2チャンネル（前方、後方）画面で
              <br />
              スイングをリアルタイムで映写し、
              <br />
              より正確な映像を実現
            </p>
          </div>
        </div>
      </Modal>

      <Modal style={{ paddingTop: '70px' }} isOpen={kioskModal} toggle={kioskToggle}>
        <div className="hardware-pop-inner">
          <button className="pop-close" onClick={kioskToggle}></button>
          <div className="hardware-pop-img">
            <img src="content/golf/img/kiosk.png" alt="" />
          </div>
          <div className="pop-txt-area">
            <p className="pop-ttl">キオスク</p>
            <p className="pop-txt">
              直感的なタッチスクリーンモニターで
              <br />
              マウスやキーボードなしで
              <br />
              便利に情報確認や設定変更が可能
            </p>
          </div>
        </div>
      </Modal>

      <Modal style={{ paddingTop: '70px' }} isOpen={keypadModal} toggle={keypadToggle}>
        <div className="hardware-pop-inner">
          <button className="pop-close" onClick={keypadToggle}></button>
          <div className="hardware-pop-img">
            <img src="content/golf/img/keypad.png" alt="" />
          </div>
          <div className="pop-txt-area">
            <p className="pop-ttl">LEDキーパッド</p>
            <p className="pop-txt">
              シンプルなデザインで
              <br />
              直感性を高めた6球形キーパッド
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GolfPracticeRangeDescription;
