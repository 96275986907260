import { IInquiryStatus } from 'app/shared/model/inquiry-status.model';
import { IInquiryNote } from 'app/shared/model/inquiry-note.model';
import { InquiryType } from 'app/shared/model/enumerations/inquiry-type.model';
import { InquiryBusinessType } from 'app/shared/model/enumerations/inquiry-business-type.model';
import { InquiryProductConsidering } from 'app/shared/model/enumerations/inquiry-product-considering.model';
import { InquiryReasonContacting } from 'app/shared/model/enumerations/inquiry-reason-contacting.model';

export interface IInquiry {
  id?: number;
  contactName?: string;
  contactNameKana?: string;
  companyName?: string | null;
  zipCode?: string;
  prefecture?: string;
  address?: string;
  emailAddress?: string;
  phoneNumber?: string;
  inquiryType?: InquiryType;
  inquiryBusinessType?: InquiryBusinessType | null;
  inquiryProductConsidering?: InquiryProductConsidering | null;
  inquiryReasonContacting?: InquiryReasonContacting | null;
  inquiryContent?: string | null;
  agreeToTerms?: boolean;
  status?: IInquiryStatus | null;
  notes?: IInquiryNote[] | null;
}

export const defaultValue: Readonly<IInquiry> = {
  agreeToTerms: false,
};
