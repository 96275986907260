/* eslint-disable */
import $ from 'jquery';
(function (win, $) {
  'use strict';

  /* header menu start */
  var headerMenu = {
    init: function () {
      this.bindEvents();
    },
    bindEvents: function () {
      $(win).on('scroll', $.proxy(this.onFixedFunc, this));
    },
    onFixedFunc: function () {
      var scrollVal = $(win).scrollTop();

      if (scrollVal > 0) {
        $('.header').addClass('fixed');
      } else {
        $('.header').removeClass('fixed');
      }
    },
  };
  /* //header menu end */

  /* mobile total menu start */
  var totalMenu = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.totalMenuBtn = $('.total-menu-btn');
      this.totalMenu = $('.total-menu');
      this.header = $('.header');
    },
    bindEvents: function () {
      this.totalMenuBtn.on('click', $.proxy(this.onOpenClickFunc, this));
      this.totalMenuBtn.on('click', $.proxy(this.onCloseClickFunc, this));
    },
    onOpenClickFunc: function (e) {
      e.preventDefault();
      var target = $(e.currentTarget);

      target.toggleClass('active');
      this.totalMenu.toggleClass('active');
      this.header.toggleClass('active');
    },
    onCloseClickFunc: function (e) {
      e.preventDefault();
      var target = $(e.currentTarget);

      if (target.hasClass('active')) {
        this.totalMenu.removeClass('hide');
      } else {
        this.totalMenu.removeClass('active');
        this.totalMenu.addClass('hide');
      }
    },
  };
  /* //mobile total menu end */

  /* main visual resize start */
  var visualResize = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.eventObj = $('.main-visual');
      this.motionTtl = $('.visual-ttl-area');
    },
    bindEvents: function () {
      $(win).on('load', $.proxy(this.onLoadFunc, this));
      $(win).on('resize', $.proxy(this.onResizeFunc, this));
    },
    onLoadFunc: function () {
      var winHeight = $(window).innerHeight(),
        winWidth = $(window).innerWidth();

      if (winWidth > 1280) {
        this.eventObj.css({
          minHeight: 920,
          height: winHeight,
        });
      } else if (winWidth <= 1280 && winWidth > 960) {
        this.eventObj.css({
          minHeight: 720,
          height: winHeight,
        });
      } else if (winWidth <= 960) {
        this.eventObj.css({
          minHeight: 'auto',
          height: winHeight,
        });
      }

      this.motionTtl.addClass('motion-active');
    },
    onResizeFunc: function () {
      var winHeight = $(window).innerHeight(),
        winWidth = $(window).innerWidth();

      if (winWidth > 1280) {
        this.eventObj.css({
          minHeight: 920,
          height: winHeight,
        });
      } else if (winWidth <= 1280 && winWidth > 960) {
        this.eventObj.css({
          minHeight: 720,
          height: winHeight,
        });
      } else if (winWidth <= 960) {
        this.eventObj.css({
          minHeight: 'auto',
          height: winHeight,
        });
      }
    },
  };
  /* //main visual resize end */

  /* main scroll start */
  var mainScroll = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.scrollBtnObj = $('.scroll-btn');
    },
    bindEvents: function () {
      this.scrollBtnObj.on('click', $.proxy(this.onClickEvent, this));
    },
    onClickEvent: function (e) {
      var target = $(e.currentTarget),
        mainVisualH = $('.main-visual').outerHeight();

      e.preventDefault();
      $('html, body').stop().animate(
        {
          scrollTop: mainVisualH,
        },
        500,
        'swing'
      );
    },
  };
  /* //main scroll end */

  /* family site start */
  var familySite = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.familySiteBtn = $('.family-site-ttl, .family-site-arrow');
    },
    bindEvents: function () {
      this.familySiteBtn.on('click', $.proxy(this.onClickEvent, this));
    },
    onClickEvent: function (e) {
      var target = $(e.currentTarget);

      e.preventDefault();

      if (target.parent().hasClass('active')) {
        target.parent().removeClass('active');
      } else {
        target.parent().addClass('active');
      }
    },
  };
  /* //family site end */

  /* main slider start */
  var mainSlider = {
    init: function () {
      this.setElements();
      this.sliderEvents();
    },
    setElements: function () {
      this.sliderObj = $('.golf-slider');
    },
    sliderEvents: function () {
      this.sliderObj.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: true,
        arrows: false,
        cssEase: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
      });
    },
  };
  /* //main slider end */

  /* top button start */
  var topBtn = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.topBtnObj = $('.top-btn');
    },
    bindEvents: function () {
      this.topBtnObj.on('click', $.proxy(this.onClickEvent, this));
      $(win).on('scroll', $.proxy(this.onScrollEvent, this));
    },
    onScrollEvent: function () {
      var scrollVal = $(win).scrollTop(),
        mainVisualH = $('.main-visual').outerHeight(),
        docH = $(document).height(),
        contentH = $(window).height() + $('.footer').outerHeight();

      if (scrollVal > mainVisualH) {
        this.topBtnObj.fadeIn();
      } else {
        this.topBtnObj.fadeOut();
      }
      if (scrollVal > docH - contentH) {
        this.topBtnObj.addClass('active');
      } else {
        this.topBtnObj.removeClass('active');
      }
    },
    onClickEvent: function (e) {
      var target = $(e.currentTarget);

      e.preventDefault();
      $('html, body').stop().animate(
        {
          scrollTop: 0,
        },
        500,
        'swing'
      );
    },
  };
  /* //top button end */

  /* tabmenu start */
  var tabMenu = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.tabBtn = $('.membership-tab');
      this.tabCont = $('.membership-conts');
    },
    bindEvents: function () {
      this.tabBtn.on('click', $.proxy(this.onClickEvent, this));
    },
    onClickEvent: function (e) {
      var target = $(e.currentTarget),
        targetIdx = target.index(),
        targetCont = this.tabCont.children();

      e.preventDefault();
      this.tabBtn.removeClass('active');
      target.addClass('active');
      targetCont.removeClass('active');
      targetCont.eq(targetIdx).addClass('active');
    },
  };
  /* //tabmenu end */

  /* input checkbox start */
  var inputCheckbox = {
    init: function () {
      this.setElements();
      this.bindEvents();
    },
    setElements: function () {
      this.inputChkBox = $('.input-chk');
    },
    bindEvents: function () {
      this.inputChkBox.on('change', $.proxy(this.onChangeEvent, this));
    },
    onChangeEvent: function (e) {
      var target = $(e.currentTarget),
        targetLabel = target.prev();

      if (target.is(':checked')) {
        target.parent().addClass('active');
      } else {
        target.parent().removeClass('active');
      }
    },
  };
  /* //input checkbox end */

  $(function () {
    headerMenu.init();
    totalMenu.init();
    visualResize.init();
    mainScroll.init();
    familySite.init();
    mainSlider.init();
    topBtn.init();
    tabMenu.init();
    inputCheckbox.init();
    if (navigator.userAgent.match(/Trident\/7\./) || window.navigator.userAgent.indexOf('Edge') > -1) {
      document.body.addEventListener('mousewheel', function () {
        event.preventDefault();
        var wd = event.wheelDelta;
        var csp = window.pageYOffset;
        window.scrollTo(0, csp - wd);
      });
    }
    // $('.input-sel').selectric({
    //   nativeOnMobile: false,
    //   onBeforeInit: function () {
    //     if ($('.input-sel').hasClass('disabled')) {
    //       $('.input-sel.disabled').attr('disabled', true);
    //     }
    //   },
    // });
  });
})(window, $);

// $(document).ready(function () {
//   $('select').selectric();
// });
// export default common;
