import React from 'react';
import InquiryUpdate from './inquiry-custom-update';
import './Contact.scss';
const Contact: React.FC = () => {
  return (
    <>
      <InquiryUpdate />
    </>
  );
};

export default Contact;
