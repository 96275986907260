import './main.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';

export const Main = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <div id="app-home">
      <a href="https://prtimes.jp/main/html/rd/p/000000002.000120337.html">
        <div className="miraku_news">
          <p className="newsContens">
            新世代型のゴルフシミュレーターが日本に上陸!!「株式会社Miraku」は「株式会社kakaoVX 」社とパートナーシップ契約を締結しました!!
          </p>
        </div>
      </a>
      <div className="mirakuImage">
        <Row>
          <span className="mirakuText">MIRAKU</span>
        </Row>
        <Row>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <span className="mirakuSubText">"Revolutionize Your Business with Innovative IT and Simulation Solutions"</span>
        </Row>
      </div>

      <div id="aboutScroll" className="mainScroll"></div>
      <div className="about">
        <div className="about-content">
          <Row className="about-underbar"></Row>
          <Row className="about-title">
            <span>ABOUT COMPANY</span>
          </Row>
          <Row className="about-body">
            <p className="font_8">
              Mirakuは、
              <br />
              シミュレーションソリューションのスぺシャリストとして、
              <br />
              お客様のビジネスの変革と成長をサポートします。
              <br />
              <br />
              革新的なテクノロジーを駆使、お客様の業務改善、生産性向上、
              <br />
              および収益力向上を支援します。
              <br />
              <br />
              ビジネスの成功のカギは、Mirakuが持っています。
              <br />
              まずは、ご相談ください。
              <br />
              <br />
            </p>
          </Row>
        </div>
      </div>

      <div id="servicesScroll" className="mainScroll"></div>
      <div className="services">
        <Row>
          <Col md="6">
            <Row className="services-content">
              <Col className="services-underbar"></Col>
              <Col className="services-title">
                <span>SERVICES</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="business-card-box-row">
          <div>
            <article className="business-card-box">
              <div className="business-card-inner">
                <a href="https://miraku.io/" className="link-overlay" target="_blank" />
                <h2 className="business-title">
                  シミュレーション
                  <br />
                  ゴルフ
                </h2>
                <h3 className="business-sub-title">
                  誰でも簡単にゴルフを楽しめて、
                  <br />
                  一緒に遊ぶ文化を作ること。
                  <br />
                  それがMirakuがフレンスアカデミー
                  <br />
                  をサービスする理由です。
                </h3>
                <div className="business-item-image">
                  <img src="content/images/biz_screen.png" alt="シミュレーションゴルフ" />
                </div>
              </div>
            </article>
          </div>
        </Row>
      </div>

      {/* <div className="services">
        <div id="servicesScroll" className="mainScroll"></div>
        <div className="sd mainTitle">
          <p className="text sd">SERVICES</p>
        </div>

        <div className="scroll-area" id="comp-lf90kfzd">
          <div>
            <article className="business-card-box">
              <div className="business-card-inner">
                <a href="http://www.friendsacademy.kr/home/golf" className="link-overlay" target="_blank" />
                <h2 className="business-title">スクリーンゴルフ</h2>
                <h3 className="business-sub-title">
                  誰でも簡単で楽しくゴルフを楽しんで、一緒に遊ぶ文化を作ること。
                  それがカカオVXがフレンズスクリーン、フレンズアカデミーをサービスする理由です。
                </h3>
                <div className="business-item-image">
                  <img src="content/images/biz_screen.png" alt="スクリーンゴルフ" />
                </div>
              </div>
            </article>
          </div>
        </div>

      </div> */}
    </div>
  );
};

export default Main;
