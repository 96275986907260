import dayjs from 'dayjs';
import { IInquiry } from 'app/shared/model/inquiry.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IInquiryStatus {
  id?: number;
  status?: Status;
  statusDate?: string;
  inquiry?: IInquiry | null;
}

export const defaultValue: Readonly<IInquiryStatus> = {};
