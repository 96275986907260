import inquiryStatus from 'app/entities/inquiry-status/inquiry-status.reducer';
import inquiryNote from 'app/entities/inquiry-note/inquiry-note.reducer';
import inquiry from 'app/entities/inquiry/inquiry.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  inquiryStatus,
  inquiryNote,
  inquiry,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
