export enum InquiryType {
  INQUIRY_TYPE_1 = 'INQUIRY_TYPE_1',

  INQUIRY_TYPE_2 = 'INQUIRY_TYPE_2',

  INQUIRY_TYPE_3 = 'INQUIRY_TYPE_3',

  INQUIRY_TYPE_4 = 'INQUIRY_TYPE_4',

  INQUIRY_TYPE_5 = 'INQUIRY_TYPE_5',
}
/**
 * ENUMに対応する日本語の定義
 */
export const InquiryTypeLabels = {
  [InquiryType.INQUIRY_TYPE_1]: '購入検討',
  [InquiryType.INQUIRY_TYPE_2]: '資料請求',
  [InquiryType.INQUIRY_TYPE_3]: '相談',
  [InquiryType.INQUIRY_TYPE_4]: '現場調査',
  [InquiryType.INQUIRY_TYPE_5]: 'その他',
};
