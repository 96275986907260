import './GolfPracticeRangeFriendsAcademy.scss';

import React from 'react';
import { Button, Row, Col, FormText, Table, Form, Input } from 'reactstrap';

const GolfPracticeRangeFriendsAcademy: React.FC = () => {
  return (
    <div className="section-contents">
      <div className="title-main">
        <p className="sub-ttl">
          <span className="title-main-txt">
            私だけのアプリ
            <br />
            フレンズアカデミー
          </span>
        </p>
      </div>
      <Row>
        <Col md="1" className="dummy1">
          <div></div>
        </Col>
        <Col md="3" className="col-content-main">
          <Row className="row-content-center">
            <div className="card-box">
              <div className="card-inner">
                <img src="content/golf/img/swing.png" className="friendsAcademy-img" alt="friendsAcademy" />
              </div>
            </div>
          </Row>
          <Row className="row-content-center-title">
            <p className="title-txt">
              <span>スイング映像</span>
            </p>
          </Row>
          <Row className="row-content-center">
            <p className="sub-caption">
              私のスイング映像を公開して点検してください。
              <br />
              フィードバックを通じてさらに成長することができます。
            </p>
          </Row>
        </Col>
        <Col md="3" className="col-content-main white-background">
          <Row className="row-content-center">
            <div className="card-box">
              <div className="card-inner">
                <img src="content/golf/img/record.png" className="friendsAcademy-img" alt="friendsAcademy" />
              </div>
            </div>
          </Row>
          <Row className="row-content-center-title">
            <p className="title-txt">
              <span>記録分析</span>
            </p>
          </Row>
          <Row className="row-content-center">
            <p className="sub-caption">
              私の実力を比較を通じて項目別に分析してくれます。
              <br />
              一目で自分の実力を確認できます。
            </p>
          </Row>
        </Col>
        <Col md="3" className="col-content-main">
          <Row className="row-content-center">
            <div className="card-box">
              <div className="card-inner">
                <img src="content/golf/img/reservation.png" className="friendsAcademy-img" alt="friendsAcademy" />
              </div>
            </div>
          </Row>
          <Row className="row-content-center-title">
            <p className="title-txt">
              <span>予約システム</span>
            </p>
          </Row>
          <Row className="row-content-center">
            <p className="sub-caption">予約システム専用アプリケーションが標準装備。</p>
          </Row>
        </Col>
        <Col md="1">
          <div></div>
        </Col>
      </Row>
      <div className="sub-ttl-area padding-bottom-70px"></div>
    </div>
  );
};

export default GolfPracticeRangeFriendsAcademy;
